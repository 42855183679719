import React from 'react'
import {
  Link as RemixLink,
  type LinkProps as RemixLinkProps
} from '@remix-run/react'
import { useLinkPrefixedWithLocale } from '~/hooks/useLinkPrefixedWithLocale'
import { cn } from '~/utils/cn'
import * as styles from './Link.styles'

export type LinkProps = RemixLinkProps &
  styles.Variants & {
    testId: string
  }

const Link = ({
  className,
  testId,
  weight,
  underline,
  to,
  ...restLinkProps
}: LinkProps) => {
  const toPrefixed = useLinkPrefixedWithLocale(to)

  return (
    <RemixLink
      className={cn(styles.linkVariants({ underline, weight }), className)}
      data-testid={`${testId}Link`}
      {...restLinkProps}
      to={toPrefixed}
    />
  )
}

export default Link
